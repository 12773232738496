import { graphql } from "gatsby";
import { convertConstellationComponentList } from "organisms";
import PropTypes from "prop-types";
import React from "react";

import ConstellationGroupPage from "../constellationGroup/constellationGroup";

const ConstellationObjectPage = ({ data }) => {
  let constellationObjectData = data?.craftAPI?.entry;
  data.craftAPI.entry.objects = constellationObjectData.parent.objects;

  // include the cover image with individual component data
  let componentList = constellationObjectData.constellationComponentList?.map(
    (c) => ({
      coverImage: constellationObjectData.coverImage,
      title: constellationObjectData.title,
      ...c,
    })
  );
  data.craftAPI.entry.children =
    convertConstellationComponentList(componentList);

  return <ConstellationGroupPage data={data} isChildObject />;
};

export const constellationQuery = graphql`
  query ($uri: [String]) {
    craftAPI {
      entry(uri: $uri) {
        ...BreadcrumbNavigationFragment
        ... on CraftAPI_constellations_constellationObject_Entry {
          id
          constellationComponentList {
            ...ConstellationComponentListFragment
          }
          coverImage {
            ...ImageMetadataFragment
          }
          parent {
            uri
            id
            parent {
              children {
                ... on CraftAPI_constellations_constellationGroup_Entry {
                  id
                  date
                  title
                  uri
                }
              }
            }
            ... on CraftAPI_constellations_constellationGroup_Entry {
              tag(label: true)
              title
              constellationImage {
                ... on CraftAPI_image_Asset {
                  ...ImageMetadataOnlyFragment
                  url(transform: "constellation")
                  imageFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              objects: children {
                ...ConstellationChildObjectsFragment
              }
            }
          }
          shortDescription
          descriptionHeader
          title
          uri
          tag(label: true)
          date
          text
        }
      }
    }
  }
`;

ConstellationObjectPage.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({
        children: PropTypes.arrayOf(PropTypes.shape({})),
        objects: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
};

export default ConstellationObjectPage;
